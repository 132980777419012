import React from "react";

import styled from "styled-components";
import BAClients from "./BAClients";

function BeforeAfter({r}) {
  return <BeforeAfterStyled ref={r}>
    <div>
      <BeforeAfterBlock>
        <h2>Before & After</h2>
        <BAClients />
      </BeforeAfterBlock>

    </div>
  </BeforeAfterStyled>
}

const BeforeAfterBlock = styled.div`
  overflow: hidden;
  padding: 35px 0;
  border-bottom: 1px solid #222;
  
  h2{
      font: 28px "Myriad Pro Bold Condensed", sans-serif;
      color: #a7152c;
      text-transform: uppercase;
      
    }
    p{
      font: 18px "Myriad Pro Light", sans-serif;
      color: #f1f1f1;
    } 
`;


const BeforeAfterStyled = styled.div`
  background: #191919;
  & > div{
    max-width: 1024px;
    margin: 0 auto;
    padding: 0 10px; 
  }
`;

export default BeforeAfter;