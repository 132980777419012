import React, {useRef} from 'react';
import styled from 'styled-components';

import Header from "../components/parts/Header";
import Poster from "../components/parts/Poster";
import Footer from "../components/parts/Footer";
import BeforeAfter from "../components/parts/BeforeAfter";
import ProgramInfo from "../components/parts/ProgramInfo";
import Testimonials from "../components/parts/Testimonials";
import Questionnaire from "../components/parts/Questionnaire";
import {useDispatch, useSelector} from "react-redux";
import {reset} from '../reducers/scrollTo';

function App() {

  const dispatch = useDispatch();
  const scrollTo = useSelector(s => s.scrollTo);

  const headerRef = useRef(null)
  const questionnaireRef = useRef(null)
  const baRef = useRef(null)
  const testimonialsRef = useRef(null)
  const infoRef = useRef(null)
  const aboutRef = useRef(null)

  React.useEffect(() => {
    document.title = "12-WEEK TRANSFORMATION PROGRAM :: IBOSpirit"

    if (scrollTo === "header" && headerRef)
      headerRef.current.scrollIntoView();

    if (scrollTo === "questionnaire" && headerRef)
      questionnaireRef.current.scrollIntoView();

    if (scrollTo === "ba" && headerRef)
      baRef.current.scrollIntoView();

    if (scrollTo === "testimonials" && headerRef)
      testimonialsRef.current.scrollIntoView();

    if (scrollTo === "about" && headerRef)
      aboutRef.current.scrollIntoView();

    dispatch(reset());

  }, [scrollTo]);



  return <Container>
    <Header r={headerRef} />
    <Poster />
    <Questionnaire r={questionnaireRef} />
    <ProgramInfo r={infoRef} />
    <BeforeAfter r={baRef} />
    <Testimonials r={testimonialsRef} />
    <Footer r={aboutRef} />
  </Container>;
}


const Container = styled.div`
  //outline: 1px solid red;
 
  min-height: 600px;
  margin: 0 auto;
`;


export default App;
