import React from "react";
import logo from "../../images/header/logo_white.png";
import bar from "../../images/header/menu.png";
import youtube from "../../images/header/icons8-youtube-24.png";
import instagram from "../../images/header/icons8-instagram-24.png";
import whatsapp from "../../images/header/icons8-whatsapp-24.png";

import styled from "styled-components";
import {useDispatch} from "react-redux";
import {scrollToAdded} from '../../reducers/scrollTo';

function Header({r}) {

  const dispatch = useDispatch();
  const [toggle, setToggle] = React.useState(false);

  const scrolling = (e, el) => {
    e.stopPropagation();
    e.preventDefault();
    dispatch(scrollToAdded(el));
  }

  return <HeaderStyled>
    <div ref={r}>
      <Logo img={logo} href={'#'}>www.ibospirit.com</Logo>
      <ButtonStyled onClick={e => setToggle(!toggle)} img={bar} />
      <Menu toggle={toggle}>
        <ul className="main-menu">
          <li><a href="#" onClick={e => scrolling(e, 'header')}>Main menu</a></li>
          <li><a href="#" onClick={e => scrolling(e, 'ba')}>Achievements</a></li>
          <li><a href="#" onClick={e => scrolling(e, 'testimonials')}>Testimonials</a></li>
          <li><a href="#" onClick={e => scrolling(e, 'about')}>About</a></li>
        </ul>
        <ul className="social-menu">
          <li><Social target="_blank" img={youtube} img2={youtube} href="https://www.youtube.com/c/ibospirit">{' '}</Social></li>
          <li><Social target="_blank" img={instagram} img2={instagram} href="https://www.instagram.com/ibospirit/">{' '}</Social></li>
          <li><Social target="_blank" img={whatsapp} img2={whatsapp} href="https://wa.me/+971565775833">{' '}</Social></li>
        </ul>
      </Menu>
    </div>
  </HeaderStyled>
}

const Social = styled.a`
  background: transparent url("${p => p.img}") no-repeat;
  background-size: contain;
  border-left: none;
  &:hover{
  background: transparent url("${p => p.img2}") no-repeat;
  }
`;

const ButtonStyled = styled.button`
  float: right;
  margin: 3px 10px 0 0;
  width: 40px; 
  height: 40px;
  display: block;
  outline: 0;
  border: 1px solid #222;
  border-radius: 5px;
  cursor: pointer;
  background: #a7152c url("${p => p.img}") no-repeat 50% 50%;
  color: #f1f1f1;
    
  @media only screen and (min-width: 600px) {
    display: none;
    float: none;
  }
`;

const Menu = styled.div`
  float: right;
  
  
  ul.main-menu {
  float: left;
    li {
      float: left;
      a{
        display: block;
        transition: all ease-in 150ms;
        padding: 0 15px;
        height: 46px;
        font: 16px "Myriad Pro Condensed", sans-serif;
        line-height: 46px;
        text-decoration: none;
        color: #fefefe;
        border-right: 1px solid #222;
        &:hover{
          background: #a7152c;
        }
      }
    }
  } 
  
  ul.social-menu {
    float: left;
    margin-left: 10px;
    padding-left: 10px;
    li {
        float: left;
        display: block;
        padding: 0;
        text-align: center;
      a{
        display: inline-block;
        width: 24px;
        height: 24px;
        margin-top: 11px;
        padding: 0 5px;
        transition: transform ease-in-out 150ms;
        &:hover{
          transform: scale(1.2);
        }
      }
    }
  }
  
  @media only screen and (max-width: 600px) {
    float: none;
    overflow: hidden;
    width: 100%;
    display: ${v => v.toggle ? 'block' : 'none'};
    
    ul.main-menu{
      border-top: 1px solid #222;
      width: 100%;
      display: block;
      float: none;
      overflow: hidden;
      
      li{
        float: none;
        display: block;
        width: 100%;
        
        a{
          border-right: none;
          border-bottom: 1px solid #222;
        }
      }
    }
    
    ul.social-menu{
      float: none;
      overflow: hidden;
      margin: 0; 
      li{
        a{
          margin: 5px;
          padding: 0;
        }
      }
    }
  } 
`;

const Logo = styled.a`
  display: block;
  text-decoration: none;
  width: 140px;
  height: 28px;
  float: left;
  text-indent: -999px;
  background: transparent url("${props => props.img}") no-repeat;
  background-size: contain;
  margin: 9px 0 0 12px;
`;

const HeaderStyled = styled.div`
  min-height: 46px;
  background: #191919;
  overflow: visible;
 
  & > div{
    max-width: 1024px;
    margin: 0 auto;
  }
`;

export default Header;