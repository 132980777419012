import React from "react";
import styled from "styled-components";
import { Swiper, SwiperSlide } from 'swiper/react';
// import Swiper core and required modules
import {Navigation, Pagination, Scrollbar, A11y, Autoplay, EffectCoverflow, EffectFlip} from 'swiper';

// import img from './../../images/carousel/hero-4.jpeg';
import ba_1 from './../../images/beforeafter/ba_1.JPG';
import ba_2 from './../../images/beforeafter/ba_2.JPG';
import ba_3 from './../../images/beforeafter/ba_3.JPG';
import ba_4 from './../../images/beforeafter/ba_4.JPG';
import ba_5 from './../../images/beforeafter/ba_5.JPG';
import ba_6 from './../../images/beforeafter/ba_6.JPG';
import ba_7 from './../../images/beforeafter/ba_7.JPG';
import ba_8 from './../../images/beforeafter/ba_8.JPG';
import ba_9 from './../../images/beforeafter/ba_9.JPG';
import ba_10 from './../../images/beforeafter/ba_10.JPG';
import ba_11 from './../../images/beforeafter/ba_11.JPG';
import ba_12 from './../../images/beforeafter/ba_12.JPG';
import ba_13 from './../../images/beforeafter/ba_13.JPG';
import ba_14 from './../../images/beforeafter/ba_14.JPG';
import ba_15 from './../../images/beforeafter/ba_15.JPG';
import ba_16 from './../../images/beforeafter/ba_16.JPG';
import ba_17 from './../../images/beforeafter/ba_17.JPG';
import ba_18 from './../../images/beforeafter/ba_18.JPG';
import ba_19 from './../../images/beforeafter/ba_19.JPG';
import ba_20 from './../../images/beforeafter/ba_20.JPG';
import ba_21 from './../../images/beforeafter/ba_21.JPG';
import ba_22 from './../../images/beforeafter/ba_22.JPG';
import ba_23 from './../../images/beforeafter/ba_23.JPG';


// Import Swiper styles
import 'swiper/css';
import 'swiper/css/bundle';


function BAClients() {
  return <Swiper
    effect={'coverflow'}
    grabCursor={true}
    // install Swiper modules
    modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay, EffectCoverflow]}
    spaceBetween={0}
    loop
    slidesPerView={window.innerWidth > 600 ? 2 : 1}
    autoplay={{delay: 5000}}
    navigation
    // pagination
    centeredSlides={true}
    coverflowEffect={{
      rotate: 50,
      stretch: 0,
      depth: 100,
      modifier: 1,
      slideShadows: true,
    }}

    // pagination={{ clickable: true }}
    // onSwiper={(swiper) => console.log(swiper)}
    // onSlideChange={(e) => { console.log(e.activeIndex); }}
  >

    <SwiperSlide>
      <SwiperWrapper>
        <img src={ba_19} alt=""/><div><h3>Yasser</h3></div>
      </SwiperWrapper>
    </SwiperSlide>

    <SwiperSlide>
      <SwiperWrapper>
        <img src={ba_20} alt=""/><div><h3>Yasser</h3></div>
      </SwiperWrapper>
    </SwiperSlide>

    <SwiperSlide>
      <SwiperWrapper>
        <img src={ba_21} alt=""/><div><h3>Gulam</h3></div>
      </SwiperWrapper>
    </SwiperSlide>

    <SwiperSlide>
      <SwiperWrapper>
        <img src={ba_22} alt=""/><div><h3>Gulam</h3></div>
      </SwiperWrapper>
    </SwiperSlide>

    <SwiperSlide>
      <SwiperWrapper>
        <img src={ba_23} alt=""/><div><h3>Gulam</h3></div>
      </SwiperWrapper>
    </SwiperSlide>

    <SwiperSlide>
      <SwiperWrapper>
        <img src={ba_1} alt=""/><div><h3>Claudia</h3></div>
      </SwiperWrapper>
    </SwiperSlide>

    <SwiperSlide>
      <SwiperWrapper>
        <img src={ba_2} alt=""/><div><h3></h3></div>
      </SwiperWrapper>
    </SwiperSlide>

    <SwiperSlide>
      <SwiperWrapper>
        <img src={ba_3} alt=""/><div><h3>Reshmaa</h3></div>
      </SwiperWrapper>
    </SwiperSlide>

    <SwiperSlide>
      <SwiperWrapper>
        <img src={ba_4} alt=""/><div><h3>Shijo</h3></div>
      </SwiperWrapper>
    </SwiperSlide>

    <SwiperSlide>
      <SwiperWrapper>
        <img src={ba_5} alt=""/><div><h3>Suzan</h3></div>
      </SwiperWrapper>
    </SwiperSlide>

    <SwiperSlide>
      <SwiperWrapper>
        <img src={ba_6} alt=""/><div><h3>Sahil</h3></div>
      </SwiperWrapper>
    </SwiperSlide>

    <SwiperSlide>
      <SwiperWrapper>
        <img src={ba_7} alt=""/><div><h3>Moustapha</h3></div>
      </SwiperWrapper>
    </SwiperSlide>

    <SwiperSlide>
      <SwiperWrapper>
        <img src={ba_8} alt=""/><div><h3>Moustapha</h3></div>
      </SwiperWrapper>
    </SwiperSlide>

    <SwiperSlide>
      <SwiperWrapper>
        <img src={ba_9} alt=""/><div><h3>Kushi</h3></div>
      </SwiperWrapper>
    </SwiperSlide>

    <SwiperSlide>
      <SwiperWrapper>
        <img src={ba_10} alt=""/><div><h3>Naveed</h3></div>
      </SwiperWrapper>
    </SwiperSlide>

    <SwiperSlide>
      <SwiperWrapper>
        <img src={ba_11} alt=""/><div><h3>Ali</h3></div>
      </SwiperWrapper>
    </SwiperSlide>

    <SwiperSlide>
      <SwiperWrapper>
        <img src={ba_12} alt=""/><div><h3>Faisal</h3></div>
      </SwiperWrapper>
    </SwiperSlide>

    <SwiperSlide>
      <SwiperWrapper>
        <img src={ba_13} alt=""/><div><h3>Ammar</h3></div>
      </SwiperWrapper>
    </SwiperSlide>

    <SwiperSlide>
      <SwiperWrapper>
        <img src={ba_14} alt=""/><div><h3>Faris</h3></div>
      </SwiperWrapper>
    </SwiperSlide>

    <SwiperSlide>
      <SwiperWrapper>
        <img src={ba_15} alt=""/><div><h3>Shijo</h3></div>
      </SwiperWrapper>
    </SwiperSlide>

    <SwiperSlide>
      <SwiperWrapper>
        <img src={ba_16} alt=""/><div><h3>Dounya</h3></div>
      </SwiperWrapper>
    </SwiperSlide>

    <SwiperSlide>
      <SwiperWrapper>
        <img src={ba_17} alt=""/><div><h3>Tania</h3></div>
      </SwiperWrapper>
    </SwiperSlide>

    <SwiperSlide>
      <SwiperWrapper>
        <img src={ba_18} alt=""/><div><h3>Max</h3></div>
      </SwiperWrapper>
    </SwiperSlide>

  </Swiper>
}

const SwiperWrapper = styled.div`
  padding: 1rem 3rem;
  margin-bottom: 1rem;
  display: block;
  position: relative;
  
  img{
    width: 100%;
  }
  
  div{
    h3{
      font: 14px "Myriad Pro Light", sans-serif;
      color: #ccc;
      
      a{
        color: aqua;
        font-style: italic;
      }
    }
  }
  
  @media only screen and (max-width: 600px) {
   padding: 1rem 0; 
  }
`;
export default BAClients;