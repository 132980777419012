import React from "react";
import styled from 'styled-components'

function randomIntFromInterval(min, max) { // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min)
}

function TextArea({value, onChange, placeholder="", label="", type='text', style={}}) {
  const id = 'inputid' + randomIntFromInterval(1, 100) + '_' + randomIntFromInterval(1, 100) + "_" +randomIntFromInterval(1, 100);
  return <InputStyled style={style} label={label}>
    <label htmlFor={id}>{label} :</label>
    <div>
      <textarea onChange={onChange} placeholder={placeholder} id={id} value={value} >
      </textarea>
    </div>
  </InputStyled>
}

const InputStyled = styled.div`
  
  label{
    flex: 1;
    display: ${p => p.label==="" ? 'none' : 'block' };
    color: #f1f1f1;
    font: 16px "Myriad Pro Light";
    margin: 0;
    padding: 0;
  }
  div{
    display: flex;
    
    textarea{
      flex: 1;
      display: block;
      min-height: 70px;
      font: 20px "Myriad Pro Light";
      padding: 7px;
      outline: 0;
      border: 1px solid #222;
      border-radius: 5px;
      &:focus{
        border: 1px solid red;
      }
  }
  }
  
`

export default TextArea;