import React from "react";
import logo from "../../images/header/logo_white.png";
import youtube from "../../images/header/icons8-youtube-24.png";
import instagram from "../../images/header/icons8-instagram-24.png";
import whatsapp from "../../images/header/icons8-whatsapp-24.png";

import styled from "styled-components";
import {useDispatch} from "react-redux";
import {scrollToAdded} from "../../reducers/scrollTo";

function Footer({r}) {

  const dispatch = useDispatch();

  const scrolling = (e, el) => {
    e.stopPropagation();
    e.preventDefault();
    dispatch(scrollToAdded(el));
  }

  return <FooterStyled ref={r}>
    <div>
      <InfoBlock>
        <div id={'#about_me'} className="info-content">
          <h2>About Ibo</h2>
          <p>My name is ibo and founder of Ibospirit. My entire life was dedicated to health and sport as my father has the discipline to keep the best shape, stable health, and inspire people. I was in different sports since I was a child and realized that fitness is exactly where my passion is. And started helping people to achieve their dream body goals. Thousands of transformations and happy faces on a daily basis make me more motivated to help more and more people as possible.</p>
          <br />
          <p>My 12-week transformation program changed many people's lives, by losing their excess belly fat up to 15kg within 90 days and getting beach body shape. The clients' results are telling for themselves. The reason is we work with dedication to get the individually customized program for each client. And if you are one of those people then you are most welcome.</p>
        </div>
        <ul className="main-menu">
          <li className="title">MENU</li>
          <li><a href="#" onClick={e => scrolling(e, 'header')}>Main menu</a></li>
          <li><a href="#" onClick={e => scrolling(e, 'ba')}>Achievements</a></li>
          <li><a href="#" onClick={e => scrolling(e, 'testimonials')}>Testimonials</a></li>
          <li><a href="#" onClick={e => scrolling(e, 'about')}>About</a></li>
        </ul>
      </InfoBlock>

      <FooterContent>

        <ul className="social-menu">
          <li><Social target="_blank" img={youtube} img2={youtube} href="https://www.youtube.com/c/ibospirit">{' '}</Social></li>
          <li><Social target="_blank" img={instagram} img2={instagram} href="https://www.instagram.com/ibospirit/">{' '}</Social></li>
          <li><Social target="_blank" img={whatsapp} img2={whatsapp} href="https://wa.me/+971565775833">{' '}</Social></li>
        </ul>
        <p>2022 &copy; www.ibospirit.com</p>
      </FooterContent>

    </div>
  </FooterStyled>
}

const Social = styled.a`
  background: transparent url("${p => p.img}") no-repeat;
  background-size: contain;
  border-left: none;
  &:hover{
  background: transparent url("${p => p.img2}") no-repeat;
  }
`;

const FooterContent = styled.div`
  border-top: 1px solid #444;
  padding: 10px 0;
  overflow: hidden;
  
  p{
    float: left;
    display: inline-block;
    color: #f1f1f1;
    font: 16px "Myriad Pro Light", sans-serif;
    line-height: 24px;
    
  }
  
  ul.social-menu {
    float: right;
    margin-left: 10px;
    padding-left: 10px;
    li {
        float: left;
        display: block;
        padding: 0;
      a{
        display: inline-block;
        width: 24px;
        height: 24px;
        padding: 0 5px;
        transition: transform ease-in-out 150ms;
        &:hover{
          transform: scale(1.2);
        }
      }
    }
  } 
`;

const InfoBlock = styled.div`
 
  display: flex;
  justify-content: space-between;
  padding: 35px 0;
  
  .info-content{
    width: auto;
    
    h2{
      font: 28px "Myriad Pro Bold Condensed", sans-serif;
      color: #a7152c;
      text-transform: uppercase;
      margin-bottom: 10px;
    }
    p{
      font: 18px "Myriad Pro Light", sans-serif;
      color: #f1f1f1;
      margin-right: 10px;
    }  
  }
  
  ul.main-menu {
    border-left: 1px solid #444;
    
    li {
      &.title{
        color: #a7152c;
        font: 20px "Myriad Pro Bold Condensed", sans-serif;
        border-bottom: 1px solid #444;
        padding-left: 10px;
      }
      a{
        text-align: center;
        display: block;
        transition: all ease-in 150ms;
        padding: 0 15px;
        height: 46px;
        font: 16px "Myriad Pro Condensed", sans-serif;
        line-height: 46px;
        text-decoration: none;
        color: #fefefe;
        white-space: nowrap;
        
        &:hover{
          color: #a7152c;
        }
      }
    }
  }
  
  @media only screen and (max-width: 600px) {
    display: block;
    .info-content{ margin-bottom: 20px;}
    
    p{
      margin-right: 0 !important;
    }
    
    ul.main-menu{
      border-left: none;
      
      
    }
    
  }
`;

const FooterStyled = styled.div`
  background: #191919;
  padding: 0 10px;
  & > div{
    max-width: 1024px;
    margin: 0 auto;
  }
`;

export default Footer;