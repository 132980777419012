import React from "react";

import styled from "styled-components";

function ProgramInfo({r}) {
  return <ProgramInfoStyled ref={r}>
    <div>
      <ProgramInfoBlock>
        <h2>12-week transformation program info</h2>
        <p>I help people lose 10-15KG of fat in 12 weeks or less. Stay lean and achieve the beach body.</p>
        <br />
        <p>The 12-week transformation program is designed for clients individually with a customized approach to ensure they are getting the best results possible.</p>
      </ProgramInfoBlock>

    </div>
  </ProgramInfoStyled>
}

const ProgramInfoBlock = styled.div`
  overflow: hidden;
  padding: 35px 0;
  border-bottom: 1px solid #222;
  
  h2{
    font: 28px "Myriad Pro Bold Condensed", sans-serif;
    color: #a7152c;
    text-transform: uppercase;
    margin-bottom: 10px;
  }
  p{
    font: 18px "Myriad Pro Light", sans-serif;
    color: #f1f1f1;
  }   
`;


const ProgramInfoStyled = styled.div`
  background: #191919;
  & > div{
    max-width: 1024px;
    margin: 0 auto; 
  }
`;

export default ProgramInfo;