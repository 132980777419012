import React from "react";
import styled from 'styled-components';

import img from './../../images/header/wrapper.jpg';
import {scrollToAdded} from "../../reducers/scrollTo";
import {useDispatch} from "react-redux";

function Poster() {
  const dispatch = useDispatch();

  const scrolling = (e) => {
    e.stopPropagation();
    e.preventDefault();
    dispatch(scrollToAdded('questionnaire'));
  }

  return <PosterStyled img={img}>
    <div>
      <div>
        <h1>Online coaching :</h1>
        <h3>Do you want to lose your belly fat and stay fit in 90 days?</h3>
        <a href="#" onClick={scrolling}>Let's start </a>
      </div>
    </div>
  </PosterStyled>
}

const PosterStyled = styled.div`
  height: 600px;
  width: 100%;
  background: url("${p => p.img}") no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
 
 
  & > div{
    display: flex;
    justify-content: center;
    flex-direction: row;
   
    
    & > div{
      text-align: center;
      background: rgba(0,0,0,0.7);
      width: 400px;
      border-radius: 20px;
      padding: 2rem;
      
      h1{
        text-transform: uppercase;
        color: #e0e0e0;
        font: 20px "Myriad Pro Bold Condensed";
        margin-bottom: 1.5rem;
      }
      
      h3{
        text-transform: uppercase;
        color: #fefefe;
        font: bold 17px Montserrat;
        margin-bottom: 1.5rem;
        line-height: 1.35rem;
      }
      
      a{
        border: none;
        display: inline-block;
        background: #a7152c;
        color: #f0f0f0;
        text-transform: uppercase;
        font: 26px "Myriad Pro Condensed";
        margin: 0;
        padding: 0 2.5rem;
        height: 45px;
        border-radius: 5px;
        line-height: 45px;
        text-decoration: none;
        box-shadow: 0 0 10px #a7152c;
        transition: all ease-in-out 400ms;
        &:hover{
          transform: scale(1.1);
        }
      }
    }
  }
  
  @media only screen and (max-width: 600px) {
    height: auto;
    
    & > div{
    
      & > div{
        width: auto;
        margin: 3rem 1rem;
        padding: 1rem;
      }
    }
  }
`;

export default Poster;