import React from "react";
import styled from 'styled-components'

function Button({onClick, theme='white', children, style, isBlock=false}) {
  return <ButtonStyled theme={theme} style={style} onClick={onClick} isBlock={isBlock}>{children}</ButtonStyled>
}

const ButtonStyled = styled.button`
    width: ${v => v.isBlock ? '100%' : 'auto'};
    height: 40px;
    display: block;
    text-transform: uppercase;
    font: 20px "Myriad Pro Light";
    line-height: 40px;
    outline: 0;
    border: 1px solid ${v => v.theme=='white' ? '#a7152c' : '#333'};
    border-radius: 5px;
    cursor: pointer;
    background: ${v => v.theme=='white' ? '#a7152c' : '#333'};
    color: #f1f1f1;
    padding: 0 20px;
    margin-top: 5px;
    transition: all ease-in-out 300ms;
    
    &:hover{
      border: 1px solid ${v => v.theme=='white' ? '#a7152c' : '#333'};
      background: ${v => v.theme=='white' ? '#f1f1f1' : '#444'};
      color: #a7152c;
    }
`

export default Button;