import React from "react";
import styled from "styled-components";
import { Swiper, SwiperSlide } from 'swiper/react';
// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y, Autoplay, EffectCoverflow } from 'swiper';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/bundle';
import test_1 from "../../images/testimonials/Ahmar.jpg";
import test_2 from "../../images/testimonials/Balazs.jpg";
import test_3 from "../../images/testimonials/Bee.jpg";
import test_4 from "../../images/testimonials/Gulam.jpg";
import test_5 from "../../images/testimonials/John.jpg";
import test_6 from "../../images/testimonials/John_2.jpg";
import test_7 from "../../images/testimonials/Jonathan.jpg";
import test_8 from "../../images/testimonials/Justin.jpg";
import test_9 from "../../images/testimonials/Max.jpg";
import test_10 from "../../images/testimonials/Max_2.jpg";
import test_11 from "../../images/testimonials/Max_3.jpg";
import test_12 from "../../images/testimonials/Mohamed.jpg";
import test_13 from "../../images/testimonials/Ruyet.jpg";
import test_14 from "../../images/testimonials/Yasim.jpg";

function Carousel() {
  return <Swiper
    effect={'coverflow'}
    grabCursor={true}
    // install Swiper modules
    modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay, EffectCoverflow]}
    spaceBetween={0}
    loop
    slidesPerView={window.innerWidth > 600 ? 2 : 1}
    autoplay={{delay: 5000}}
    navigation
    // pagination
    centeredSlides={true}
    coverflowEffect={{
      rotate: 50,
      stretch: 0,
      depth: 100,
      modifier: 1,
      slideShadows: true,
    }}
  >
    <SwiperSlide>
      <SwiperWrapper>
        <img src={test_1} alt=""/><div><h3>Ahmar</h3></div>
      </SwiperWrapper>
    </SwiperSlide>

    <SwiperSlide>
      <SwiperWrapper>
        <img src={test_2} alt=""/><div><h3>Balazs</h3></div>
      </SwiperWrapper>
    </SwiperSlide>

    <SwiperSlide>
      <SwiperWrapper>
        <img src={test_3} alt=""/><div><h3>Bee</h3></div>
      </SwiperWrapper>
    </SwiperSlide>

    <SwiperSlide>
      <SwiperWrapper>
        <img src={test_4} alt=""/><div><h3>Gulam</h3></div>
      </SwiperWrapper>
    </SwiperSlide>

    <SwiperSlide>
      <SwiperWrapper>
        <img src={test_5} alt=""/><div><h3>John</h3></div>
      </SwiperWrapper>
    </SwiperSlide>

    <SwiperSlide>
      <SwiperWrapper>
        <img src={test_6} alt=""/><div><h3>John</h3></div>
      </SwiperWrapper>
    </SwiperSlide>

    <SwiperSlide>
      <SwiperWrapper>
        <img src={test_7} alt=""/><div><h3>Jonathan</h3></div>
      </SwiperWrapper>
    </SwiperSlide>

    <SwiperSlide>
      <SwiperWrapper>
        <img src={test_8} alt=""/><div><h3>Justin</h3></div>
      </SwiperWrapper>
    </SwiperSlide>

    <SwiperSlide>
      <SwiperWrapper>
        <img src={test_9} alt=""/><div><h3>Max</h3></div>
      </SwiperWrapper>
    </SwiperSlide>

    <SwiperSlide>
      <SwiperWrapper>
        <img src={test_10} alt=""/><div><h3>Max</h3></div>
      </SwiperWrapper>
    </SwiperSlide>

    <SwiperSlide>
      <SwiperWrapper>
        <img src={test_11} alt=""/><div><h3>Max</h3></div>
      </SwiperWrapper>
    </SwiperSlide>

    <SwiperSlide>
      <SwiperWrapper>
        <img src={test_12} alt=""/><div><h3>Mohamed</h3></div>
      </SwiperWrapper>
    </SwiperSlide>

    <SwiperSlide>
      <SwiperWrapper>
        <img src={test_13} alt=""/><div><h3>Ruyet</h3></div>
      </SwiperWrapper>
    </SwiperSlide>

    <SwiperSlide>
      <SwiperWrapper>
        <img src={test_14} alt=""/><div><h3>Yasim</h3></div>
      </SwiperWrapper>
    </SwiperSlide>

  </Swiper>
}


const SwiperWrapper = styled.div`
  padding: 1rem 3rem;
  margin-bottom: 1rem;
  display: block;
  position: relative;
  
  img{
    width: 100%;
  }
  
  div{
    h3{
      font: 14px "Myriad Pro Light", sans-serif;
      color: #ccc;
      
      a{
        color: aqua;
        font-style: italic;
      }
    }
  }
  
  @media only screen and (max-width: 600px) {
   padding: 1rem 0; 
  }
`;
export default Carousel;