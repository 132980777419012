import {createSlice} from "@reduxjs/toolkit";

const scrollToSlice = createSlice({
  name: 'scrollTo',
  initialState: null,
  reducers: {
    scrollToAdded: (state, action) => {
      return action.payload;
    },
    reset: () => {
      return null;
    }
  }
});

export const {scrollToAdded, reset} = scrollToSlice.actions;

export default scrollToSlice.reducer;