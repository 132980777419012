import React from "react"
import styled from "styled-components"
import country from 'country-list-js'
import Select2 from 'react-select'


// Import Swiper styles
import Input from "../forms/Input"
import Button from "../forms/Button"
import TextArea from "../forms/TextArea"
import ibospirit from "../../connect/ibospirit";
import {urls} from "../../connect/urls";


function QuestionStep() {

  const defaultQ = {
    step: 0,
    count: 5,
    name: '',
    age: '',
    gender: {value: 1, label: 'Male'},
    country: {value: 226, label: 'United Arab Emirates'},
    whatsapp: '',
    email: '',
    note: ''
  };

  const [q, qSet] = React.useState(defaultQ);

  const [countries, setCountries] = React.useState(country.names().map( (e, k) => ({value: k+1, label: e})));
  const [isLoading, setIsLoading] = React.useState(false);

  const nextButton = () => {
    if (q.step === 1 && (q.name.length < 2 || q.age < 10 || q.age > 150) )
      return 0;

    let validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (q.step === 3 && !(q.email.match(validRegex)) )
      return 0;

    // next
    qSet({...q, step: q.step+1});
  }

  const backButton = () => {

    // back
    qSet({...q, step: q.step-1});
  }

  const resetButton = () => {
    // reset to default value
    qSet(defaultQ);
  }

  const sendButton = async () => {
    try {
      setIsLoading(true);

      const response = await ibospirit.post(urls.sendEmail, {
        ...q,
        country: q.country.label,
        gender: q.gender.value
      });

      // next
      qSet({...q, step: q.step+1});

    } catch (e) {
      try {
        const status = parseInt(e.response.status);
        switch (status) {
          // If input data has error
          case 400:
            if (typeof e.response.data == "object") {
              const data = e.response.data;
              let errorText = "";
              Object.keys(data).forEach(key => errorText += data[key] + "\n");
              alert(errorText);
            }
            break;

          // If token expired
          case 401:
            alert(e.response.data);
            break;

          // If made many requests
          case 429:
            alert('Too many request try again later');
            break;

          // If none matches
          default:
            alert('none');
        }

      } catch (e) {
        alert('Network error. Check internet connection');
      }
    } finally {
      setIsLoading(false);
    }
  }



  return <div style={{position: 'relative'}}>
    {/* intro section */}
    <SwiperWrapper currentStep={q.step} step={0}>
      <ProgressStyled q={q}><div>{" "}</div></ProgressStyled>
      <h2 style={{fontSize: 20, marginBottom: 15, textAlign: 'center', marginTop: '10px'}}>Start your transformation to lose belly fat and stay lean in 90 days!</h2>
      <Button isBlock={true} onClick={nextButton} style={{marginBottom: '10px'}}>let's get started</Button>
    </SwiperWrapper>

    {/* Name & age */}
    <SwiperWrapper currentStep={q.step} step={1}>
      <ProgressStyled q={q}><div>{" "}</div></ProgressStyled>
      <Input value={q.name} onChange={(e) => qSet({...q, name: e.target.value})} label={"Name"} placeholder={"Name"} />
      <Input value={q.age} onChange={(e) => qSet({...q, age: e.target.value})} style={{marginTop: '15px', marginBottom: '25px'}} label={"Age"} placeholder={"Age please: 50"} />
      <div style={{marginTop: '25px', display: 'flex', justifyContent: 'space-between'}}>
        <Button isBlock theme={'dark'} style={{flex: 0.48}} onClick={backButton}>Back</Button>
        <Button isBlock style={{flex: 0.48}} onClick={nextButton}>Next</Button>
      </div>
    </SwiperWrapper>

    {/* Gender & country */}
    <SwiperWrapper currentStep={q.step} step={2}>
      <ProgressStyled q={q}><div>{" "}</div></ProgressStyled>
      <label style={{ color: '#f1f1f1', marginBottom: '3px', }}>Gender :</label>
      <Select2 onChange={(e) => qSet({...q, gender: e})}
               defaultValue={q.gender}
               placeholder={'Choose your gender ...'}
               options={[{value: 1, label: 'Male'}, {value: 0, label: 'Female'}]}
               isSearchable
      />

      <label style={{color: '#f1f1f1', marginTop: '15px', marginBottom: '3px', display: 'block'}}>Country :</label>
      <Select2 value={q.country}
               defaultValue={q.country}
               placeholder={'Choose your country ...'}
               onChange={(e) => { qSet({...q, country: e}); }}
               isSearchable
               options={countries}
      />

      <div style={{marginTop: '25px', display: 'flex', justifyContent: 'space-between'}}>
        <Button isBlock theme={'dark'} style={{flex: 0.48}} onClick={backButton}>Back</Button>
        <Button isBlock style={{flex: 0.48}} onClick={nextButton}>Next</Button>
      </div>
    </SwiperWrapper>

    {/* Email & whatsapp */}
    <SwiperWrapper currentStep={q.step} step={3}>
      <ProgressStyled q={q}><div>{" "}</div></ProgressStyled>
      <Input value={q.email} onChange={(e) => qSet({...q, email: e.target.value})} type={'email'} label={"E-mail"} placeholder={"E-mail required"} />
      <Input value={q.whatsapp} onChange={(e) => qSet({...q, whatsapp: e.target.value})} style={{marginTop: '10px', marginBottom: '20px'}} label={"Whatsapp"} placeholder={"Optional"} />

      <div style={{marginTop: '25px', display: 'flex', justifyContent: 'space-between'}}>
        <Button isBlock theme={'dark'} style={{flex: 0.48}} onClick={backButton}>Back</Button>
        <Button isBlock style={{flex: 0.48}} onClick={nextButton}>Next</Button>
      </div>
    </SwiperWrapper>

    {/* Extra info */}
    <SwiperWrapper currentStep={q.step} step={4}>
      <LoaderStyled isLoading={isLoading}><div><h1>Sending ...</h1></div></LoaderStyled>
      <ProgressStyled q={q}><div>{" "}</div></ProgressStyled>
      <TextArea
        value={q.note} onChange={(e) => qSet({...q, note: e.target.value})}
        label={"Extra info"}
        placeholder={"Extra notes about your body condition and what is your target ..."}
        style={{marginBottom: '20px'}}
      />
      <div style={{marginTop: '25px', display: 'flex', justifyContent: 'space-between'}}>
        <Button isBlock theme={'dark'} style={{flex: 0.48}} onClick={backButton}>Back</Button>
        <Button isBlock style={{flex: 0.48}} onClick={sendButton}>Next</Button>
      </div>
    </SwiperWrapper>

    {/* Ending */}
    <SwiperWrapper currentStep={q.step} step={5}>
      <ProgressStyled q={q}><div>{" "}</div></ProgressStyled>
      <h2 style={{fontSize: 20, textAlign: 'center', marginTop: '10px', marginBottom: 0}}>Thank you! I've received your information!</h2>
      <p style={{textAlign: 'center', marginTop: '10px', fontSize: 14}}>I'll contact you for a chat about how I can help you.</p>
      <Button isBlock onClick={resetButton}>Okay</Button>
    </SwiperWrapper>
  </div>
}

const ProgressStyled = styled.div`
  position: absolute;
  top: 10px; left: 0;
  width: 100%;
  height: 3px;
  background: #333;
    
  div{
    width: ${v => (100 / v.q.count * v.q.step) + '%'};
    height: 3px;
    background: #a7152c;
  }
`;


const SwiperWrapper = styled.div`
  padding: 2rem;
  margin: 0 auto 2rem;
  display: ${v => v.currentStep === v.step ? 'block' : 'none' };
  position: relative;
  max-width: 300px;
  border: 1px solid #333;
  border-radius: 10px;
  background: #202020;
  

  p{
    color: #ccc;
    padding-bottom: 0.5rem;
  }
`;


const LoaderStyled = styled.div`
  position: absolute;
  top: 0; left: 0;
  background: rgba(0,0,0, 0.4);
  width: 100%;
  height: 100%;
  display: ${p => p.isLoading ? 'flex' : 'none'};
  justify-content: center;
  flex-direction: column;
  border-radius: 10px;
  
  div{
    display: flex;
    justify-content: center;
   
    h1{
    color: #f1f1f1;
    font-size: 20px;
    text-align: center;
    background: #c41010;
    padding: 10px;
    border-radius: 10px;
    animation: ke 500ms ease-in-out infinite;
    box-shadow: 0 0 10px #222222;
    
    @keyframes ke {
      0% {
        transform: scale(1);
      }
      50%{
      transform: scale(1.1);
      }
      100%{
      transform: scale(1);
      }
    }
  }
  }
  
`;


export default QuestionStep;