import React from "react";

import styled from "styled-components";
import QuestionStep from "./QestionStep";

function Questionnaire({r}) {
  return <QuestionnaireStyled ref={r}>
    <div>
      <QuestionnaireBlock>
        <h2 style={{textAlign: 'center'}}>12-week transformation program</h2>
        <QuestionStep />
      </QuestionnaireBlock>
    </div>
  </QuestionnaireStyled>
}


const QuestionnaireBlock = styled.div`
  
  padding: 4rem 0;
  border-bottom: 1px solid #222;
  
  h2{
    font: 28px "Myriad Pro Bold Condensed", sans-serif;
    color: #f1f1f1;
    text-transform: uppercase;
    margin-bottom: 2rem;
  }
    
`;


const QuestionnaireStyled = styled.div`
  background: #222;
  & > div{
    max-width: 1024px;
    margin: 0 auto; 
  }
`;


export default Questionnaire;